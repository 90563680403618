@forward "../../../styles/common.scss";
@use "../../../../src/themes/variables.scss" as vars;

.MuiToolbar-regular {
  min-height: 62px !important;
}

.headerLeft {
  width: 30%;
  background-color: white;
  float: left;
  @media (max-width: 767px) {
    width: auto;
  }
}
.feature-buttons {
  transition: 0.5s all linear;
  width: 100%;
}
.featured-align {
  @media (min-width: 960px) {
    margin-top: 20px !important;
  }
}

div#TabsResponsive button:last-child .feature-buttons {
  margin-right: 0px;
}

div#TabsResponsive button:first-child .feature-buttons {
  margin-left: 0px;
}

span.MuiBadge-root.feature-buttons {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.feature-buttons:first-child {
  margin-left: 0px !important;
}

#TabsResponsive > div > div > div {
  display: flex;
  margin: auto;
  justify-content: space-between;
  text-align: center;
  align-items: flex-start;
}
.search-bar {
  display: none;
}

.search-box {
  height: 35px;
  border-radius: 50px;
  background-color: #f1f1f1;
  width: 100%;
  border: none;
  padding: 5%;
}

.inner-search-box {
  border-radius: 50px;
  width: 200px;
  background-color: #f1f1f1;
  width: 100%;
  border: none;
  height: 70%;
  padding: 17px;
}

.outer-search-box {
  display: flex;
  align-items: center;
  position: relative;
  height: 4vh;
  width: 20vw;
}
.course-search-box {
  display: flex;
  align-items: center;
  position: relative;
  padding: 20px 20px 20px;
  @media (min-width: 960px) {
    display: none;
  }
  @media (max-width: 767px) {
    padding: 20px 15px 0;
    &.no-mobile-padding {
      padding: 20px 0;
      fieldset {
        border-color: transparent !important;
      }
    }
  }
}
.outer-search-box input {
  outline: none !important;
}

.course-search-box input {
  outline: none !important;
  fieldset {
    border: 0 !important;
  }
}

.search-img-box {
  position: absolute;
  right: 10px;
  button {
    background: transparent;
  }
}

.course-search-img-box {
  position: absolute;
  right: 28px;
}

.search-icon {
  margin-top: 5px;
  width: 75%;
  @media (min-width: 320px) and (max-width: 768px) {
    margin-left: 19px;
    width: 50%;
  }
}

.cross-icon {
  margin-top: 5px;
  width: 20px;
  cursor: pointer !important;

  @media (min-width: 1024px) {
    width: 17px;
  }
}

.mobile-language-header {
  display: none;
}

.display-none {
  color: #000 !important;
}

.removeSidebar {
  display: none;
}

.showSidebar {
  display: block;
}

.bottom-link {
  > div {
    padding: 0;
  }
}

.drawer-close {
  width: 35px;
  height: 35px;
  display: none;
  position: absolute;
  right: 5px;
  top: 3px;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;

  i {
    color: #000;
    font-size: 19px;
    line-height: 35px;
    display: block;
    width: 100%;
    text-align: center;
  }
}

img.main-logo {
  position: absolute;
  max-width: 60px;
  left: 31px;
  height: 60px;
  bottom: -24px;
  display: none;
  z-index: 9;
  border-radius: 50%;
}

.user-section {
  padding: 12px 0 0;
  background: #00bfa5;
  position: relative;
  display: none;

  .notification-bar {
    ul.right-menus {
      margin: 0;

      li {
        margin: 0 10px;
        display: inline-block;
        padding: 4px 13px 0;
        position: relative;
        vertical-align: middle;
        color: #fff;

        span.fa-language {
          color: #fff;
          font-size: 26px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
        }

        .sub-menus {
          position: absolute;
          top: 42px;
          z-index: 10;
          background: #fff;
          width: 200px;
          box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
          right: 0;
          height: auto;
          padding: 0;
          float: none;
          margin: 0;
          display: none;

          li {
            display: block;
            margin: 0;
            padding: 10px 16px;
            text-align: left;
            font-weight: 500;
            color: grey;
            font-size: 15px;
            cursor: pointer;

            &:hover {
              background: rgba(0, 0, 0, 0.05);
            }

            a {
              width: 100%;
              color: grey;
              text-align: left;
              display: block;
              font-weight: 500;
              font-size: 15px;
            }

            &.selected {
              background: rgba(0, 0, 0, 0.05);
            }
          }
        }

        &:hover {
          background-color: #fff;
          color: #000;

          span.fa-language {
            color: #000;
          }

          a {
            color: #000;
          }

          i {
            color: #000;
          }

          .sub-menus {
            display: block;
          }
        }

        i {
          font-size: 12px;
          color: #fff;
          margin-left: 2px;
        }

        a {
          text-decoration: none;
          background: none;
          font-size: 14px;
          padding: 0 5px;
          cursor: pointer;
          color: #fff;

          &.pass-btn {
            background: #075175;
            color: #fff;
            padding: 5px 25px;
            border-radius: 3px;
          }
        }

        &.login-field,
        &.user-detail {
          position: relative;

          a {
            width: 22px;
            height: inherit;
            display: inline-block;
            padding: 0;
            border-radius: 50%;
            vertical-align: sub;

            img {
              width: 100%;
            }
          }

          i {
            color: #fff;
            font-size: 12px;
          }

          .sub-menus {
            position: absolute;
            top: 50px;
            background: #fff;
            width: 200px;
            box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
            right: 0;
            height: auto;
            z-index: 9;
            padding: 0;
            float: none;
            margin: 0;
            display: none;

            li {
              display: block;
              margin: 0;
              padding: 10px 16px;

              &:hover {
                background: rgba(0, 0, 0, 0.05);
              }

              a {
                width: 100%;
                color: grey;
                text-align: left;
                display: block;
                font-weight: 500;
                font-size: 15px;
                background: none;
                height: inherit;
              }
            }
          }

          &:hover {
            background-color: #fff;

            i {
              color: #000;
            }

            .sub-menus {
              display: block;
            }
          }
        }
      }
    }
  }

  h4 {
    color: #fff;
    margin: 0;
    float: none;
    padding-left: 0;
    display: inline-block;
    font-size: 17px;
  }
}

.removeText {
  @media (max-width: 767px) {
    margin: 0 !important;
    padding: 0 6px !important;
    img {
      width: 25px !important;
    }
  }

  > span {
    @media (max-width: 767px) {
      display: none;
    }
  }
}

.sidebar-vip-btn {
  @media (max-width: 767px) {
    position: static;
    width: auto !important;
    top: -20px;
    padding: 0 !important;
    right: 10px;
    display: flex !important;
    justify-content: flex-end;
    margin-bottom: 0px !important;
  }
}

.mobile-menu-list {
  display: none;
}

.main-header-section {
  padding-left: 4px !important;
  padding-right: 4px !important;
  background-color: #fff;
}

.listing {
  overflow-y: auto;
  overflow-x: hidden;

  .MuiTypography-displayBlock {
    @media (max-width: 767px) {
      color: #000;
    }
  }

  .bottom-listing {
    > div {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  a {
    text-decoration: none;
    display: block;

    &:hover {
      background: #062235;
    }

    &.active {
      span {
        font-weight: bold;
      }
    }

    > div {
      padding-top: 8px;
      padding-bottom: 8px;

      &:hover,
      &:focus {
        background-color: transparent !important;
      }

      > div {
        padding: 0 !important;
      }
    }
  }

  i {
    padding-left: 4px;
    margin-right: 21px;
    color: red;
    font-size: 20px;

    &.m-r-18 {
      margin-right: 18px;
    }

    &.m-r-16 {
      margin-right: 16px;
    }

    &.fa-file-text {
      margin-right: 18px;
    }
  }
}

.toggled-menu {
  background: none;
  width: auto;
  margin: 13px auto;
  color: #fff;
  text-align: left;
  padding: 5px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  float: right;

  &.desk-menu {
    display: inline-flex;
    margin: 0;

    .toggle-btn {
      color: #000000;
    }
  }

  &.mob-menu {
    display: none;
    background: #039be5;
    width: 80%;

    .toggle-btn {
      color: #000000;
    }
  }

  .toggle-btn {
    width: 100%;
    color: #000000;
    text-align: left;
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }

  .toggle-menu {
    transform: initial !important;
    top: -16px !important;
    min-width: 140px;
    left: 0;
    right: 0;
    z-index: 9999;

    > div {
      border-radius: 0;
    }

    ul {
      padding: 0;
      min-height: 63px;

      li {
        padding: 12px 15px;
        margin: 0;
        font-size: 13px;
        line-height: 24px;
        min-height: 30px;
        color: #888;

        &.Mui-selected {
          color: #2962ff;
          font-weight: bold;
          background-color: transparent;
        }

        &.add-more-exams {
          background: none;
          color: #fff;
          text-align: center;
          line-height: 24px;
          font-size: 12px;
          border-radius: 5px;
          padding: 6px 0;
          position: absolute;
          right: -5px;
          bottom: -10px;
          display: flex;
          justify-content: flex-end;

          &:hover {
            background: none;
          }

          &.MuiMenuItem-root {
            width: auto;
          }

          i {
            right: 0;
            bottom: 0;
            font-size: 16px;
            position: static;
            background: #008e76;
            display: block;
            vertical-align: middle;
            width: 30px;
            text-align: center;
            height: 30px;
            line-height: 30px;
            border-radius: 50%;
            top: inherit;
            transform: rotate(0);
          }
        }
      }
    }
  }

  span {
    display: inline-block;
    font-size: 14px;
    min-height: 24px;
    font-weight: 500;
    white-space: nowrap;
    width: auto;
  }

  i {
    position: absolute;
    right: -16px;
    top: 0;
    font-size: 24px;
    display: inline-block;

    &.fa-chevron-up {
      display: none;
    }
  }
}

.mobile-logo {
  display: none;

  img {
    width: 100%;
    display: block;
  }
}

.notification-bar {
  float: right;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin-right: 16px;

  ul.right-menus {
    padding: 0;
    margin: 0;
    display: flex;
    float: right;
    align-items: center;
    white-space: nowrap;
    gap: 10px;

    li {
      margin: 0 10px;
      display: inline-block;
      padding: 11px 10px;
      position: relative;
      vertical-align: middle;
      cursor: pointer;

      span.fa-language {
        color: #000;
        font-size: 26px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
      }

      .sub-menus {
        position: absolute;
        top: 41px;
        z-index: 10;
        background: #fff;
        width: 200px;
        box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
        height: auto;
        padding: 0;
        float: none;
        margin: 0;
        display: none;
        border-radius: 6px;

        li {
          display: block;
          margin: 0;
          padding: 10px 16px;
          text-align: left;
          font-weight: 500;
          color: grey;
          font-size: 15px;
          cursor: pointer;

          &:hover {
            background: rgba(0, 0, 0, 0.05);
          }

          a {
            width: 100%;
            color: grey;
            text-align: left;
            display: block;
            font-weight: 500;
            font-size: 15px;
          }

          &.selected {
            background: rgba(0, 0, 0, 0.05);
          }
        }
      }

      .notification-sub-menus {
        position: absolute;
        top: 52px;
        z-index: 10;
        background: #fff;
        width: 200px;
        box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
        height: auto;
        padding: 0;
        float: none;
        margin: 0;

        li {
          display: block;
          margin: 0;
          padding: 10px 16px;
          text-align: left;
          font-weight: bold;
          color: grey;
          font-size: 15px;
          cursor: pointer;

          &:hover {
            background: rgba(0, 0, 0, 0.05);
          }

          a {
            width: 100%;
            color: grey;
            text-align: left;
            display: block;
            font-weight: bold;
            font-size: 15px;
          }

          &.selected {
            background: rgba(0, 0, 0, 0.05);
          }
        }
      }

      &:hover {
        background-color: #fff;
        color: #000;

        a {
          color: #000;
        }

        i {
          color: #000;
        }

        .sub-menus {
          display: block;
        }

        .notification-sub-menus {
          display: block;
        }
      }

      i {
        font-size: 12px;
        color: #fff;
        margin-left: 2px;
      }

      a {
        text-decoration: none;
        background: none;
        font-size: 14px;
        padding: 0 5px;
        cursor: pointer;
        color: #fff;

        &.pass-btn {
          background: #075175;
          color: #fff;
          padding: 5px 25px;
          border-radius: 3px;
        }
      }

      &.login-field {
        position: relative;
        // login field image logo
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          filter: brightness(1);
          display: block;
        }

        a {
          width: 22px;
          height: inherit;
          display: inline-block;
          padding: 0;
          border-radius: 50%;
          vertical-align: sub;

          img {
            width: 100%;
          }
        }

        i {
          color: #fff;
          font-size: 22px;
        }

        .sub-menus {
          position: absolute;
          top: 42px;
          background: #fff;
          width: 200px;
          box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
          height: auto;
          z-index: 9;
          padding: 0;
          float: none;
          margin: 0;
          display: none;

          li {
            display: block;
            margin: 0;
            padding: 5px 16px;

            &:hover {
              background: rgba(0, 0, 0, 0.05);
            }

            a {
              width: 100%;
              color: grey;
              text-align: left;
              display: block;
              font-weight: 500;
              font-size: 15px;
              background: none;
            }
          }
        }

        &:hover {
          background-color: #fff;

          i {
            color: #000;
          }

          .sub-menus {
            display: block;
            right: 0;
          }
        }
      }
    }
  }
}

.main-header {
  position: fixed;
  width: 100%;
  top: 0;
  background: #039be5;
  left: 0;
  height: 57px;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.2), 0 0 14px rgba(0, 0, 0, 0.2);

  .main-logo {
    width: auto;
    text-align: left;
    padding: 5px 0;
    float: left;

    img {
      width: 100%;
      max-width: 150px;
      display: block;
      cursor: pointer;
    }
  }

  #menuToggle {
    display: none;
    position: relative;
    z-index: 1;
    float: left;
    padding: 8px 12px;
    cursor: pointer;

    i {
      font-size: 28px;
      color: #fff;
    }
  }
}
.drawer-logo-main {
  background: red !important;
}
.logo-main {
  width: auto;
  display: flex;
  align-items: center;

  svg {
    margin-bottom: 0 !important;
    margin-left: 20px !important;
    color: #fff;
  }
  img {
    width: 100px;
    flex-shrink: 0;
    margin-top: 0px;
    cursor: pointer;
    object-fit: contain;
  }
}

.arrow-icon {
  margin-bottom: 24px;
  margin-left: 13px;
  cursor: pointer;
}

.menu-icon {
  margin-left: 14px;
  margin-right: 14px;
}

button.renew-plan {
  background-color: #aba552;
  color: white;
  padding: 5px 12px;
  width: auto;
  text-transform: capitalize;

  &:hover {
    background-color: #aba552;
  }

  > span {
    color: #fff !important;
    font-size: 14px !important;
  }
}

button.header-plan {
  background-color: #003acb;
  color: white;
  padding: 5px 12px;
  width: auto;
  text-transform: capitalize;

  &:hover {
    background-color: #003acb;
  }

  > span {
    color: #fff !important;
    font-size: 14px !important;
  }
}

button.header-become-plan {
  color: white;
  padding: 5px 12px;
  width: auto;
  text-transform: capitalize;

  > span {
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 600;
  }
}

.button-submit {
  Button {
    float: right;
    background-color: #2962ff;
    color: #fff;
    margin-left: 10px;
    font-weight: bold;
    font-size: 14px;

    &:hover {
      background-color: #2962ff;
    }
  }
}

@media (max-width: 767px) {
  .mobile-language-header {
    display: block;

    .right-menus {
      display: block !important;
      margin-left: 0 !important;

      > li {
        > img {
          width: 25px !important;
          vertical-align: -6px !important;
        }

        > span {
          display: none;
        }
      }
    }
  }
  .Header-drawerHeader-11 {
    min-height: 50px !important;
  }
  .premium-label {
    position: absolute !important;
    top: 6px;
    right: 10px;
  }
  .search-bar {
    float: right;
    width: 38%;
    display: none;
    text-align: right;
    position: absolute;
    right: 0;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: inline-block;
        font-size: 18px;
        vertical-align: middle;
        padding-right: 20px;
      }
    }
  }
  .listing {
    max-height: calc(100vh - 140px);
  }
  .drawer-close {
    display: block;
    z-index: 9;
  }
  .display-none {
    display: none !important;
  }
  img.main-logo {
    display: block;
    left: 10px;
    bottom: -14px;
  }
  .user-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    background: #fff;
    position: relative;
    margin-top: -40px;
    padding-left: 80px;
    padding-bottom: 20px;

    .fa-pencil {
      position: absolute;
      left: 53px;
      color: #fff;
      top: 30px;
      background: #006857;
      font-size: 10px;
      width: 20px;
      height: 20px;
      line-height: 20px;
      border-radius: 50%;
      z-index: 9;
      cursor: pointer;
    }

    h4 {
      font-size: 14px;
      width: 100%;
      text-align: left;
      padding-top: 0;
      margin-left: 7px;
      color: #000;
      margin-bottom: 5px;
    }

    .notification-bar {
      display: inline-block;
      width: auto;
      vertical-align: middle;
      float: none;

      ul.right-menus {
        display: block;

        li {
          font-size: 12px;

          span.fa-language {
            color: #000;
            font-size: 14px;
          }

          .sub-menus {
            top: 43px;
          }
        }
      }
    }
  }
  .mobile-menu-list {
    display: block;
    position: static;
    left: 0;
    right: 0;
    bottom: 40px;
    padding-top: 10px;
    border-top: 1px solid #ddd;
  }
  .toggled-menu {
    span {
      display: flex;
      align-items: center;

      i {
        float: right;
        margin-left: 10px;
        margin-top: 3px;
        position: static;
      }
    }

    .toggle-menu {
      top: -11px !important;
    }

    &.desk-menu {
      width: auto;
      float: left;
      margin: 8px 0 8px 10px;
      display: none;
    }

    &.exam-switcher {
      width: auto;
      text-align: left;
      display: block;
      margin: 0;

      button {
        color: #000 !important;
        display: flex;
        justify-content: space-between;
        border-radius: 0;
        width: 100%;

        span {
          width: 100%;

          i {
            margin-top: 0;
            transform: rotate(0);
            color: #000;
          }
        }
      }
    }
  }
  .button-submit {
    a {
      padding: 7px 17px;
    }
  }
  .drawer-mobile {
    > div {
      z-index: 1202;
    }
  }
  .mobile-logo {
    display: none !important;
  }
  .header-bar header {
    width: 100%;
    margin-left: 0;
    background-color: #0c3450 !important;
    box-shadow: none;
  }
  .logoImg > img {
    width: 7rem !important;
  }
  .header-bar header > div {
    padding-left: 0 !important;
    padding-right: 0px !important;
    min-height: 50px !important;
  }
  .notification-bar {
    width: 100%;
    display: block;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.quiz-notification-bar {
      ul.right-menus {
        display: block !important;
        margin-right: 0;
        margin-left: 0;
      }
    }

    ul.right-menus {
      margin-right: 0;
      display: none !important;

      li {
        margin: 0;
      }
    }
  }
  .logo-main {
    display: none !important;
  }
  .main-header {
    line-height: 52px;

    ul {
      li {
        &.login-field {
          a {
            width: 32px;
            height: 32px;
          }
        }
      }
    }

    .main-logo {
      padding: 7px 0;
      display: none;

      img {
        max-width: 130px;
      }
    }

    #menuToggle {
      display: inline-block;
    }
  }
}

@media (min-width: 768px) {
  .header-bar header {
    width: 100%;
    margin-left: 0;
    background-color: #0c3450 !important;
    box-shadow: none;
  }
}
@media (min-width: 768px) {
  .logoImg > img {
    width: 7rem;
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  .toggled-menu {
    &.desk-menu {
      display: inline-block;
      width: auto;
      float: right;
    }
  }

  .header-bar header > div {
    padding-left: 0;
    padding-right: 0;
    min-height: 56px;
  }
  .notification-bar {
    width: 80% !important;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
}
@media (max-width: 959px) {
  .notification-bar {
    display: none;
  }
}

@media (min-width: 1101px) and (max-width: 1226px) {
  .notification-bar {
    width: 85% !important;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
  .toggled-menu {
    width: auto;
  }
}

.quiz-notification-bar {
  width: 100% !important;
  float: left;
  text-align: left;
  @media (max-width: 767px) {
    width: auto !important;
  }

  li {
    padding: 15px 10px !important;
    color: #fff !important;

    .fa {
      color: #fff !important;
    }

    &:hover {
      color: #000 !important;

      .fa {
        color: #000 !important;
      }
    }

    .sub-menus {
      li {
        color: #000 !important;
      }
    }
  }
}

.notification-bar ul.right-menus li {
  line-height: 38px;
  color: #000;
}
.reports-navigation {
  font-size: 1rem;
  font-weight: 400;
  color: vars.$content-color;
  display: flex;
  gap: 4px;
  cursor: pointer;
}
.reportsTitle {
  padding: 0px !important;
  font-size: 1rem;
  span {
    font-size: 1rem;
    font-weight: 400;
    color: vars.$content-color;
  }
}
.text-box-mobile {
  width: 100%;
  @media (max-width: 767px) {
    display: none;
  }
}
.text-box {
  padding-left: 16px;
  width: 100%;
}
.greeting-text {
  align-items: center;
  text-align: left;
  margin-left: 3%;
  font-size: 20px !important;
  font-weight: 500 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30ch;
}
.greeting-text-name {
  align-items: center;
  text-align: left;
  margin-left: 3%;
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30ch;
}
.greeting-text-mobile {
  font-size: 16px;
  font-weight: 500 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 56vw;
}
.drawer-left-head {
  width: 100%;
  padding: 10px 0;
}
.notification-bar ul.right-menus li {
  img {
    vertical-align: -8px;
    @media (max-width: 767px) {
      filter: brightness(1);
    }
  }
}

.notification-bar ul.right-menus li:hover img {
  filter: brightness(1);
}

ul.right-menus-new {
  margin: 0;
  padding: 0;

  li {
    margin: 0 0px;
    display: inline-block;
    padding: 4px 5px 0;
    position: relative;
    vertical-align: middle;
    color: #fff;

    span.fa-language {
      color: #fff;
      font-size: 26px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }

    .sub-menus {
      position: absolute;
      top: 40px;
      z-index: 10;
      background: #fff;
      width: 150px;
      box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
      right: 0;
      height: auto;
      padding: 0;
      float: none;
      margin: 0;
      display: none;

      li {
        display: block;
        margin: 0;
        padding: 10px 16px;
        text-align: left;
        font-weight: 500;
        color: grey;
        font-size: 15px;
        cursor: pointer;

        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }

        a {
          width: 100%;
          color: grey;
          text-align: left;
          display: block;
          font-weight: 500;
          font-size: 15px;
        }

        &.selected {
          background: rgba(0, 0, 0, 0.05);
        }
      }
    }

    &:hover {
      background-color: #fff;
      color: #000;

      span.fa-language {
        color: #000;
      }

      a {
        color: #000;
      }

      i {
        color: #000;
      }

      .sub-menus {
        display: block;
      }
    }

    i {
      font-size: 12px;
      color: #fff;
      margin-left: 2px;
    }

    a {
      text-decoration: none;
      background: none;
      font-size: 14px;
      padding: 0 5px;
      cursor: pointer;
      color: #fff;

      &.pass-btn {
        background: #075175;
        color: #fff;
        padding: 5px 25px;
        border-radius: 3px;
      }
    }
  }
}
.mobile-language-header-new {
  display: block;
  margin: 0 10px;

  .right-menus-new {
    display: block !important;
    margin-left: 0 !important;

    > li {
      padding: 5px;
      background-color: #fff;
      border-radius: 10px;
      > img {
        width: 25px !important;
        vertical-align: -6px !important;
        background-color: #fff;
      }

      > span {
        display: none;
      }
    }
  }

  @media (min-width: 768px) {
    display: none;
  }
}
#mainHeaderSectionId {
  background: #ffffff;
  width: 100%;
  justify-content: space-between;
  padding: 0;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
}

#headerBarId {
  width: 100%;
  height: 100%;
  background: #fafafa;
}

#languageImageId {
  width: 40px;
  height: 40px;
}

#userNotificationId {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin-right: 10px !important;
  background-color: transparent;
}

.notification-image:hover {
  animation: move 0.5s;
  animation-iteration-count: initial;
}

@keyframes move {
  0% {
    transform: rotate(0deg);
    transition: 0.3s all ease-in-out;
  }
  25% {
    transform: rotate(20deg);
    transition: 0.3s all ease-in-out;
  }
  50% {
    transform: rotate(-20deg);
    transition: 0.3s all ease-in-out;
  }
  100% {
    transform: rotate(0deg);
    transition: 0.3s all ease-in-out;
  }
}

.badge {
  position: absolute;
  top: -7px;
  right: -13px;
  min-width: 24px;
  border-radius: 50%;
  background-color: #cc2c3d;
  color: white;
  height: 24px;
}

.notification-text {
  font-size: 13px;
  font-weight: 400;
  justify-content: space-between;
  display: flex;
  color: #000;
  margin: 0;
  margin-left: 20px;
  width: 100%;
  align-items: flex-start;
  @media (max-width: 767px) {
    flex-direction: column;
    width: calc(100% - 60px);
    word-break: break-word;
    .notification-detail-heading {
      font-size: 12px !important;
      width: 100%;
    }
  }
}

.notification-time {
  color: #00000080;
  font-size: 11px;
  font-weight: 400;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.notification-delete:hover {
  animation: shake 0.5s;
  animation-iteration-count: initial;
}

.notification-delete {
  height: 100%;
}

.notification-detail-time-delete {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    margin-top: 4px;
    button {
      padding: 0;
      min-width: inherit;
      img {
        width: 20px;
      }
    }
  }
}

.notification-time-text {
  margin-right: 12px !important;
  font-weight: 500 !important;
  width: max-content;
}

.notification {
  display: flex;
  align-items: center;
}

.profile-pic {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.notification-text p {
  margin: 0;
}

.no-new-notification-text {
  padding: 0px 16px 30px;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  padding-top: 30px;
}

.see-all {
  display: block;
  text-align: center;
  height: 42px;
  width: 100%;
  &:hover {
    background: #0000000a !important;
  }
}

.see-all-text {
  color: #00000099;
  font-weight: 500;
  font-size: 10px;
  text-transform: capitalize;
}

.notification-count {
  font-size: 12px;
  display: block;
  height: inherit;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub-menu-align {
  width: 450px !important;
  right: 0;
  border-radius: 6px;
  cursor: default;
}

.notification-items {
  padding: 6px 20px !important;
  line-height: 20px !important;
  position: relative;
}

.notification-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 35ch;
}

.notification-heading {
  font-size: 12px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification-info {
  color: #00000080;
  font-size: 11px;
  position: relative;
}

.notification-list-content {
  justify-content: space-between;
  display: grid;
}

.notification-container {
  padding: 20px 16px;
  height: calc(100svh - 90px);
  overflow-y: auto;
  .count-div {
    padding: 20px 0;
    h6 {
      font-size: 18px;
    }
  }
}

.markReadButton {
  border-radius: 4px;
  background-color: vars.$primary-color !important;
  cursor: pointer;
  p {
    font-size: 14px;
    font-weight: 600;
    color: vars.$greyish-color;
    line-height: 24px;
    text-transform: capitalize;
  }
}

.unmarkReadButton {
  border-radius: 4px;
  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-transform: capitalize;
  }
}

.notificationHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notification-detail-items {
  padding: 12px !important;
  line-height: 26px !important;
  list-style: none;
  border-bottom: 1px solid #eaeaea;
  background-color: white;
  position: relative;
}

.notification-unread {
  background-color: vars.$headerBackground;
  margin-bottom: 10px;
}

.notification-dot {
  position: absolute;
  top: 40%;
  right: 10px;
  width: 10px;
  height: 10px;
  background-color: vars.$primary-color;
  border-radius: 50%;
}

.notification-detail-profile-pic {
  width: 48px;
  height: 48px;
  background: #8080802b;
  align-self: center;
  position: relative;
  top: 1px;
  border-radius: 50%;
  object-fit: cover;
  @media (max-width: 767px) {
    align-self: auto;
  }
}

.notification-delete-msg {
  right: 20px;
  padding: 10px;
  position: fixed;
  top: 76px;
  z-index: 1;
  background-color: green;
  color: white;
  border-radius: 20px;
  width: 300px;
  text-align: center;
  font-weight: 600;
}

.notification-detail-day {
  color: #84818a !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  padding: 10px 0px 10px 0px;
}

.notification-detail-heading {
  font-size: 16px !important;
}

.notification-detail-info {
  color: #00000080;
  font-size: 13px !important;
}

@media (max-width: 767px) {
  .notification-detail-info {
    color: #00000080;
    font-size: 12px !important;
  }
  .notification-detail-heading {
    font-size: 1rem !important;
    width: max-content;
  }
  .notification-detail-items {
    padding: 15px !important;
  }
  .notification {
    margin-bottom: 0;
  }
}

.notification-count-text {
  color: #84818a;
  font-size: 16px !important;
  @media (max-width: 767px) {
    padding-top: 0;
  }
}

.no-notification-content {
  text-align: center;
  color: #0c3450;
  font-size: 20px;
  font-weight: 500;
  display: block;
  padding: 3px;
}

.user-detail-box {
  display: flex;
  > div {
    margin-right: 10px;
  }
}

.right-menus {
  > li {
    &.user-detail {
      padding: 0 !important;
    }
    padding: 0 !important;
  }
}

#userNotificationId {
  margin: 0px;
  display: flex;
  .notification-sub-menus {
    right: 0;
  }
  &:hover {
    background: transparent;
  }
}

#languageSwitchId {
  margin: 0px;
  display: flex;
  .sub-menus {
    right: 0;
  }
  &:hover {
    background: transparent;
  }
}

#drawerLeftHeadId {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70px;
}

#contentBlockId {
  font-family: "Poppins", sans-serif !important;
  margin: 0;
}
.profile-info {
  margin: 0;
  font-size: 16px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 18ch;
  display: flex;
  line-height: 20px;
  @media (max-width: 375px) {
    max-width: 8ch;
  }
}

.nav-menus {
  padding: 15px 5px 0;
  // @media (max-width: 1280px) {
  //   padding-top: 30px;
  // }
}
.MuiDrawer-paper {
  background-color: #0c3450;
}

.evaluation-Test {
  background-color: #fafafafa !important;
}

.logo-size {
  height: 62px;
  cursor: pointer;
  margin: auto;
}

.divider-align {
  background-color: gray;
  border: none;
  height: 1;
  margin: 18px 0px;
}

.brand-header-logo {
  display: none;
  @media (max-width: 767px) {
    display: flex;
    width: 120px;
  }
}

.mobile-notification-screen {
  display: none;
  @media (max-width: 767px) {
    display: flex;
    margin-left: auto;
    margin-right: 5px;
    button {
      padding: 0;
    }
    svg {
      color: #fff;
      font-size: 28px;
    }
  }
}
.mainNotificationContainer {
  padding: 20px;
}
