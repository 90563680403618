.support-container {
  padding: 20px 16px 100px;
}

@media (min-width: 960px) {
  .support-container {
    padding: 20px 16px 65px 16px;
  }
}
@media (min-width: 959px) {
  .support-box {
    margin: 80px 16px 80px 16px;
    margin-top: 60px;
  }
}
@media (max-width: 959px) {
  .support-box {
    margin: 16px 16px 80px 16px !important;
  }
}
.support-box {
  margin: 20px 16px 80px 16px;
}

.support-service-box {
  border: 1px solid rgba(12, 52, 80, 0.2);
  background: #fff;
  box-shadow: 0px 0px 7.9px 0px rgba(180, 180, 180, 0.25);
  padding: 16px;
  margin-top: 16px;
}
.custom-select {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 0 10px;
}

.MuiSelect-select:focus {
  background-color: #fff !important;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.MuiInput-underline:after {
  border-bottom: none !important;
}

.subject-input {
  div {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 4px 10px;
  }
  ::placeholder {
    font-size: 14px;
  }
}

.message-input {
  div {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 8px 10px;
  }
  ::placeholder {
    font-size: 14px;
  }
}

.email-input {
  div {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 0 10px;
  }
  ::placeholder {
    font-size: 14px;
  }
}

.support-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  padding: 20px 40px;
}

.support-message {
  font-weight: normal;
}

.confirm-support {
  border-radius: 0px;
  padding: 6px 30px;
  font-size: 15px;
  margin: 15px;
  background-color: #0c3450;
  color: white;
  cursor: pointer;
  font-weight: 500;
}

.red-star {
  color: red;
  font-size: 14px !important;
}

.toast {
  position: fixed;
  right: 20px;
  padding: 20px;
  background-color: #0c3450;
  color: #fff;
  z-index: 1;
  font-weight: 500;
}

.header {
  color: #0c3450;
  font-size: 32px;
  font-weight: 700;
  margin: 0px 0px 20px;
}

.description {
  color: #84818a;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
}

.form-label {
  color: #0c3450 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  margin-bottom: 6px !important;
  display: inline-block;
}

.form-box {
  margin-bottom: 20px;
}

.submit-button {
  margin-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  gap: 15px;
  .submit-btn {
    background-color: #0c3450;
    text-transform: capitalize;
    margin-bottom: 10px;
    border-radius: 5px;
    &:hover {
      background: #0c3450e0;
    }
  }
}
.back-btn {
  border: 1px solid #0c3450 !important;
  text-transform: capitalize !important;
  margin-bottom: 10px !important;
  border-radius: 5px;
  color: #0c3450 !important;
}

.new-service {
  margin-top: 20px;
}

.service-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

.attachment-section {
  display: flex;
  align-items: center;
}

.attachment-message {
  font-size: 12px;
  color: #666;
  &:before {
    content: "(";
  }
  &:after {
    content: ")";
  }
}

.file-size {
  font-size: 12px;
  color: red;
  padding-left: 8px;
}

.validation-text {
  font-size: 12px;
  color: red;
  margin-top: 4px;
}
