::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: #84818a;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.outerBox1 {
  border: 1px solid #e7e7e7ee;
  min-height: 20px;
  //  cursor: pointer;
  padding: 5px;
  transition: 0.3s all linear;
  @media (max-width: 767px) {
    padding: 0 !important;
  }
}

.outerBox3 {
  border: 1px solid #e7e7e7ee;
  min-height: 20px;
  cursor: pointer;
  padding: 5px;
  transition: 0.3s all linear;
}

.innerBox1 {
  align-items: center;
  display: flex;
  margin: 9px;
  gap: 10px;
}

.button-box1 {
  display: flex;
  align-items: center;
  padding: 10px;
}

.card1 {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 70%;
  align-items: initial;
}

.content-box1 {
  width: 100%;
}

.isHover {
  &:hover {
    background-color: #ececec;
  }
}
.outerBox2 {
  // border: 1px solid #E7E7E7EE;
  // margin-bottom: 3px;
  cursor: pointer;
}

.innerBox {
  align-items: center;
  display: flex;
  margin: 9px;
  gap: 10px;
  @media (max-width: 767px) {
    margin: 0 !important;
    flex-wrap: wrap;
    .image-box {
      margin-left: 10px;
      margin-top: 10px;
    }
    .button-box {
      width: auto !important;
      margin-left: auto;
      padding: 0 10px;
    }
    .card {
      margin-top: 10px;
    }
    button {
      width: 100% !important;
      border-radius: 0px 0px 8px 8px;
      background: #eff2f3;
      box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.1);
      border: 0 !important;
      padding: 0 !important;
      span {
        color: #0c3450 !important;
        font-size: 12px !important;
        padding: 7px 0;
      }
    }
  }
}

.content {
  font-size: 16px;
  color: #000000 !important;
  opacity: 60% !important;
  font-weight: 500;
}

#selfLearningTitle {
  font-size: 16px;
  color: #000000 !important;
  opacity: 60% !important;
  font-weight: 500;
}

.dropdown-content {
  // border-top: 1px solid #E7E7E7EE;
  width: 100%;
  // margin-top: -5px;
  overflow-y: scroll;
  transition: 0.3s all linear;
}

.dropdown-content > div:last-child {
  border-radius: 0px 0px 6px 6px;
}

.drop-button {
  min-width: 10px;
  padding: 10px;
}

.button-box {
  display: flex;
  align-items: center;
  margin-left: auto;
  display: flex;
  justify-content: end;
  gap: 20px;
}

.minutes-read {
  display: inherit;
  gap: 6px;
  align-items: center;
  @media (max-width: 767px) {
    p {
      font-size: 12px !important;
    }
  }
}

.image-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(176, 190, 197, 0.31);
  min-width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 16px;
  svg {
    width: 30px;
    height: 30px;
  }
}

.content-box {
  width: 100%;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 50%;
  align-items: initial;
}

.submenu-content {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  gap: 10px;
  width: -webkit-fill-available;
  font-size: 16px;
  color: #000000;
  opacity: 60%;
  font-weight: 400;
}

.submenu-image {
  text-align: center;
  width: 40px;
  margin-top: 3px;
  margin-left: 7px;
  margin-right: 3px;
  img {
    padding: 4px;
    height: 90%;
  }
}

.rotated-img {
  transform: rotate(180deg);
  transition: transform 0.3s;
}
